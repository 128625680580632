.contentReservation {
  max-width: 50%;
  margin: 10px auto;
  background: #FFFFFF;
  padding: 10px;
  text-align: center;
  transition: max-width 0.3s ease; /* Añade una transición para suavizar el cambio */
}
/* Media query para pantallas más pequeñas */
@media (max-width: 768px) {
  .contentReservation {
      max-width: 100%; /* Incrementa el ancho máximo en pantallas más pequeñas */
  }
}

@media (max-width: 480px) {
  .contentReservation {
      max-width: 100%; /* Incrementa aún más el ancho máximo en pantallas muy pequeñas */
  }
}

.reservationForm {
  display: flex;
  flex-direction: column;
}

.reservationFormGroup {
  display: flex;
  flex-direction: column;
  
}

.professionalList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.professionalCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
}

.professionalCard.selected {
  background-color: #c9adad;
}
.professionalCard:hover {
  box-shadow:  0 4px 8px #410000;
}

.professionalImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}




.serviceCard.selectedService {
  background-color: #c9adad;
}

.timeList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-content: space-between;
  justify-content: center;
}

.timeButton {
  cursor: pointer;
  border: 2px solid #a98888;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-weight: bold;
  color: #685050;
}

.timeButton.selectedTime {
  background-color: #c9adad;
  font-weight: bold;
    color: white;
}

.validationError {
  color: #c30000;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}


.reservationCtaButton {
  background-color: #a38e31;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  min-width: 150px;
}

.reservationCtaButton:hover {
  background-color: #a98888;
}

.reservationSuccessAlert {
  margin-top: 20px;
  color: green;
}




.reservationOverlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.reservationAvailabilityList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.reservationAvailable {
  color: green;
}

.reservationReserved {
  color: red;
}

.infoModalButton {
  background-color: #c9adad;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.infoModalButton:hover {
  background-color: #a98888;
}

/* custom */




.profesionalNombre {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #685050;
}
.dvProfesionales {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente (opcional) */
}
.lblSubtitulo {
  font-size: 1.6em;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #685050;
}
.categoryButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

/* Estilo para pantallas pequeñas (por ejemplo, menos de 768px de ancho) */
@media (max-width: 768px) {
  .categoryButtonContainer {
    flex-direction: column; /* Cambia la dirección del flex a columna */
    gap: 5px; /* Ajusta el espacio entre los botones para que sea menor en pantallas pequeñas */
  }
  
  .categoryButton {
    width: 100%; /* Ocupa el ancho completo de la pantalla */
    max-width: 100%; /* Asegura que no se expanda más allá del ancho disponible */
    box-sizing: border-box; /* Incluye padding y border en el cálculo de width */
  }
}

.categoryButton {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  transition: background-color 0.3s ease;
  border: 2px solid #a98888;
  color: #685050;
  font-weight: bold;
  min-width: 100px;
}

.categoryButton.selectedCategory {
  background-color: #c9adad;
}

.servicesOffered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.serviceCard {
  border: 1px solid #a98888;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-width: 300px; /* Máximo ancho en pantallas normales */
  width: 100%; /* Ocupar todo el ancho del contenedor */
}

/* Estilo para pantallas pequeñas (por ejemplo, menos de 768px de ancho) */
@media (max-width: 768px) {
  .serviceCard {
    padding: 5px;
    max-width: 100%; /* Ocupa el 100% del ancho disponible en mobile */
    width: 100%; /* Asegura que ocupe todo el ancho del contenedor */
  }
}

.serviceCard.selectedService {
  background-color: #e9e2e2;
}

.botonCategoriaSelected {
  background-color: #a98888;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #e9e2e2;
  /* padding: 10px; */
  border-top: 2px solid #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.lblNombreServ {
  font-size: 14px;
  margin-top: 1px;
  margin-bottom: 1px;
  font-weight: bold;
  color: #685050;
}
.lblDuracionServ {
  font-size: 9px;
  margin-top: 10px;
  margin-bottom: 1px;
  font-weight: bold;
  color: grey;
}
.masInfo {
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 1px;
  font-weight: bold;
}
.btnAgendar {
  background-color: #a98888;
  color: white;
  border: none;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 15px;
  padding: 5px;
}
.lbltxtfooter {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
  color: #685050;
  display: flex;
}
.divFooter1{
  max-width: 250px;
}


.reservationCtaButtonAceptar {
  background-color: #458538;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  min-width: 150px;
}

.reservationCtaButtonCancelar {
  background-color: #740404;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  min-width: 150px;
}
.infoIcon{
  font-size: 40px;
}




/* Contenedor que maneja la posición del ícono y el input */
.inputWithIcon {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
}

.inputWithIcon i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #685050;
  padding: 9px;
  pointer-events: none; /* Para que el icono no interfiera con el input */
}

.inputWithIcon input[type="text"],
.inputWithIcon input[type="tel"],
.inputWithIcon input[type="email"] {
  width: calc(100% - 55px); /* Calcula el ancho restando el espacio del ícono */
  padding-left: 40px; /* Deja espacio suficiente para el ícono a la izquierda */
  border: 2px solid #685050;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
  margin-left: 55px;
}

.inputWithIcon input[type="text"]:focus,
.inputWithIcon input[type="tel"]:focus,
.inputWithIcon input[type="email"]:focus {
  border-color: #685050;
  box-shadow: 0 0 8px 0 #685050;
}

.inputWithIcon input[type="text"]:focus + i,
.inputWithIcon input[type="tel"]:focus + i,
.inputWithIcon input[type="email"]:focus + i {
  color: #685050;
}

.highlightReserva {
  border: 4px solid red !important;
  box-shadow: 0 0 10px red !important;
  background-color: yellow !important; /* Prueba también con un fondo de color */
}





.reservationAvailabilityList {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Cambia a 1fr 1fr para dos columnas o 1fr para una */
  gap: 10px;
}

.availabilityCard {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: transform 0.2s ease-in-out;
}

.available {
  background-color: #dff0d8; /* Verde claro para disponible */
  border-left: 4px solid #3c763d; /* Verde más oscuro */
}

.reserved {
  background-color: #f2dede; /* Rojo claro para reservado */
  border-left: 4px solid #a94442; /* Rojo más oscuro */
}

.timeBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.timeText {
  font-weight: bold;
  font-size: 16px;
  margin-right: 10px;
}

.statusText {
  font-size: 14px;
  margin-right: 10px;
}

.iconAvailable, .iconReserved {
  font-size: 18px;
}

.availabilityCard:hover {
  transform: scale(1.05);
}

.mostChosenLabel {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #ff8577;
  color: white;
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 3px;
}

.serviceHeader {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.serviceCard.mostChosen {
  background-color: #f0e6f6; /* Un color suave para resaltar */
  border-color: #d5b9d6; /* Un borde sutil que combine */
}


.disabledStep {
  position: relative;
  pointer-events: none;
  opacity: 0.5; /* Reduce la opacidad del bloque deshabilitado */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1; /* Asegura que la capa esté encima del contenido */
  pointer-events: none;
}