.reservationModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  width: auto;
  z-index: 1050;
  max-height: 90vh; /* Limitar la altura máxima del modal */
  overflow-y: auto; /* Habilitar scroll dentro del modal */
}

/* Media query para pantallas pequeñas */
@media (max-width: 768px) {
  .reservationModal {
    max-width: 100%;
    width: 92%;
    padding: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0;
    max-height: 90vh; /* Limitar la altura máxima del modal en pantallas pequeñas */
    overflow-y: auto; /* Habilitar scroll dentro del modal */
  }
}

.reservationOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
}


.infoModalButton {
  background-color: #c9adad;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.infoModalButton:hover {
  background-color: #a98888;
}

.closeButton {
  background: none;
  border-radius: 5px;
  font-size: 40px;
  width: 60px;
  cursor: pointer;
  color: #cfcece;
  background-color: #740404;
}

.closeButton:hover {
  color: #f7f7f7;
  background-color: #ffa0a0;
}
.lblContenido {
  font-size: 17px;
  margin-top: 10px;
  margin-bottom: 1px;
  font-weight: bold;
  color: grey;
}






.confirmationContent {
  margin-bottom: 20px;
  line-height: 1.6;
}

.confirmationText {
  font-size: 16px;
  margin: 10px 0;
}

.confirmationLabel {
  font-weight: bold;
  color: #685050;
}

.confirmationActions {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.reservationCtaButtonAceptar {
  background-color: #458538;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: background-color 0.3s ease;
}

.reservationCtaButtonAceptar:hover {
  background-color: #3a6b30;
}

.reservationCtaButtonCancelar {
  background-color: #a94442;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: background-color 0.3s ease;
}

.reservationCtaButtonCancelar:hover {
  background-color: #8a3330;
}

i.fas {
  font-size: 18px;
}
.lblSubtituloModal {
  font-size: 1.6em;
  font-weight: bold;
  color: #685050;
}
.contenidoModalTitulo{
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}