.srvOfrecidoContent {
  max-width: 80%;
  margin: 20px auto;
  background: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.srvOfrecidoTitulo {
  color: #816969;
  font-size: 25px;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
}

.srvOfrecidoForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.srvOfrecidoFormGroup {
  display: flex;
  flex-direction: column;
}

.srvOfrecidoInput, .srvOfrecidoSelect {
  width: 100%;
  padding: 8px;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}

.srvOfrecidoButton {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  color: #FFFFFF;
  background-color: #909090;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.srvOfrecidoButton:hover {
  background-color: #c9adad;
}

.srvOfrecidoTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.srvOfrecidoTable th,
.srvOfrecidoTable td {
  border: 1px solid #E0E0E0;
  text-align: center;
  padding: 10px;
}

.srvOfrecidoTable th {
  background-color: #c9adad;
  color: #FFFFFF;
}

.srvOfrecidoTable td {
  background-color: #FFFFFF;
}

.srvOfrecidoEditButton {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
}

.srvOfrecidoEditButton:hover {
  background-color: #5a6268;
}

.srvOfrecidoDeleteButton {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.srvOfrecidoDeleteButton:hover {
  background-color: #c82333;
}

.srvOfrecidoServiceList {
  list-style-type: none;
  padding: 0;
}

.srvOfrecidoServiceItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.srvOfrecidoServiceImage {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.srvOfrecidoServiceItem button {
  margin-left: 10px;
}
