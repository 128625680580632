.experiences {
    padding: 20px;
  }
  
  .experiences h2 {
    text-align: center;
  }
  
  .experiences ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .experiences li {
    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    width: 200px;
    text-align: center;
  }
  
  .experiences img {
    width: 100%;
    height: auto;
  }
  
  .experiences p {
    padding: 10px;
    background: #f9f9f9;
  }
  