.contenedor-carrusel {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
}

.mes-seleccionado {
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: rgb(44, 57, 65);
  margin-bottom: 10px;
}

.botones-superior, .botones-inferior {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.boton-carrusel {
  background-color: #a98888;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.boton-carrusel:disabled {
  background-color: #A0A0A0;
  cursor: not-allowed;
}

.boton-carrusel:hover:not(:disabled) {
  background-color: #a98888;
}

.boton-semana-actual {
  background-color: #a98888;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.boton-semana-actual:hover {
  background-color: #a98888;
}

.carrusel-semana {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.carrusel-dia {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  color: rgb(44, 57, 65);
  padding: 3px;
  border-radius: 10px;
  margin: 0 2px;
  width: 40px;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.carrusel-dia.seleccionado {
  background-color: #a98888 !important;
  color: white;
  border-color: #0056b3;
}

.carrusel-dia.fondo-rojo {
  background-color: rgb(255, 224, 224);
}

.lblDia {
  padding-bottom: 10px;
  margin-top: 7px;
  font-size: 13px;
}

.lblNumero {
  font-weight: bold !important;
  font-size: 20px;
}

.lblHoy {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: black;
  color: white;
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 3px;
}

.boton-flecha {
  background-color: transparent;
  border: none;
  font-size: 30px;
  cursor: pointer;
}

.boton-flecha:disabled {
  color: transparent;
  cursor: not-allowed;
}
