.manage-reservations-content {
    padding: 7px;
}





.reservation-list {
    display: flex;
    flex-wrap: wrap; /* Permite que las tarjetas se envuelvan a la siguiente línea si no caben en una sola fila */
    justify-content: center;
}

.reservation-cardporServicio {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    padding: 10px;
    flex: 1 1 auto; /* Permite que las tarjetas se ajusten automáticamente */
    min-width: 200px; /* Ancho mínimo para las tarjetas */
    max-width: 300px; /* Ancho máximo para las tarjetas */
    box-sizing: border-box; /* Incluye el padding y el borde en el ancho y alto total del elemento */
    margin: 10px; /* Espacio entre tarjetas */
}

@media (max-width: 768px) {
    .reservation-cardporServicio {
        flex: 1 1 100%; /* Ancho completo en pantallas más pequeñas */
        max-width: 100%;
    }
}
.divAlignCenter{
    text-align: center;
}


.reservation-cardporServicio:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.reservation-cardporServicio-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
}

.reservation-cardporServicio-header h3 {
    margin: 0;
    font-size: 20px;
    color: #333;
}

.reservation-cardporServicio-actions {
    display: flex;
    gap: 10px;
}

.reservation-cardporServicio-actions button {
    background-color: #c9adad;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.reservation-cardporServicio-actions button:hover {
    background-color: #a98888;
}

.reservation-cardporServicio-body {
    margin: 5px 0;
    color: #555;
    font-size: 14px;
    display: flex;
    flex-direction: column; /* Alinea los elementos en una columna */
    gap: 5px; /* Añade un espacio entre los elementos */
}

.manage-reservations-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    text-align: center;
}

.manage-reservations-week-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.manage-reservations-weekly-schedule-container {
    overflow-x: auto;
    margin-top: 20px;
}

.manage-reservations-weekly-schedule {
    width: 100%;
    border-collapse: collapse;
}

.manage-reservations-weekly-schedule th,
.manage-reservations-weekly-schedule td {
    border: 1px solid #ddd;
    padding: 4px;
    text-align: center;
}

.manage-reservations-weekly-schedule th {
    background-color: #72001c;
    color: #FFFFFF;
}

.manage-reservations-reserved {
    background-color: #f8d7da;
}
.botonesRes {
    background-color: #a98888;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 10px 5px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    width: auto; /* Permite que el botón se ajuste automáticamente al contenido */
    transition: all 0.3s ease; /* Añade una transición suave para cambios */
}

/* Media Query para pantallas pequeñas */
@media (max-width: 768px) {
    .botonesRes {
        padding: 8px 16px; /* Reduce el padding en dispositivos móviles */
        font-size: 14px; /* Reduce el tamaño de la fuente en dispositivos móviles */
        margin: 8px 4px; /* Ajusta el margen para dispositivos móviles */
    }
}

/* Media Query para pantallas muy pequeñas */
@media (max-width: 480px) {
    .botonesRes {
        padding: 6px 12px; /* Reduce aún más el padding en pantallas muy pequeñas */
        font-size: 12px; /* Reduce el tamaño de la fuente en pantallas muy pequeñas */
        margin: 6px 3px; /* Ajusta el margen para pantallas muy pequeñas */
    }
}
.professional-list {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.lblNombreServ {
    font-size: 14px;
    font-weight: bold;
    color: #685050;
  }

  .lblDuracionServ {
  font-size: 9px;
  font-weight: bold;
  color: grey;
  
}
.masInfo {
  font-size: 13px;
  font-weight: bold;
}
.lblCalendario
{
    font-size: 0.5em;
}


.reserved-block {
    background-color: rgba(255, 99, 132, 0.5); /* Cambia el color aquí */
    height: 100%; 
    width: 100%; 
    display: block; /* Asegura que ocupe toda la celda */
    border-radius: 4px; /* Opcional: para esquinas redondeadas */
  }
  
  .weekly-calendar td {
    padding: 0;
    height: 60px; /* Ajusta la altura según sea necesario */
    position: relative;
  }
  
  .weekly-calendar-container {
    width: 100%;
  }
  
  .weekly-calendar {
    width: 100%;
    border-collapse: collapse;
  }
  
  .weekly-calendar th, .weekly-calendar td {
    border: 1px solid #ddd;
    text-align: center;
  }
  
  .weekly-calendar th {
    background-color: #f4f4f4;
  }
  .botonesRes.active {
    background-color: #8d1f2f; /* Color de fondo del botón activo */
    color: white; /* Color de texto del botón activo */
  }
  .filter-group {
    display: flex;
    justify-content: center; /* Centra los botones horizontalmente */
    align-items: center; /* Alinea los botones verticalmente si tienen diferentes alturas */
    gap: 10px; /* Espacio entre los botones */
  }