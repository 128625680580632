/* ManageProfessionals.module.css */

.manageProfessionalsContainer {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.manageProfessionalsHeader {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.manageProfessionalsForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.manageProfessionalsInput {
    padding: 10px;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    font-size: 16px;
}

.manageProfessionalsFileInput {
    padding: 10px;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    font-size: 16px;
}

.manageProfessionalsButton {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.manageProfessionalsButton:hover {
    background-color: #45A049;
}

.manageProfessionalsList {
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
}

.manageProfessionalsListItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #E0E0E0;
}

.manageProfessionalsListItem img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.manageProfessionalsListItemNameEmail {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;
}

.manageProfessionalsEditButton,
.manageProfessionalsDeleteButton {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.manageProfessionalsEditButton {
    background-color: #FFC107;
    color: #FFFFFF;
}

.manageProfessionalsEditButton:hover {
    background-color: #E0A800;
}

.manageProfessionalsDeleteButton {
    background-color: #F44336;
    color: #FFFFFF;
}

.manageProfessionalsDeleteButton:hover {
    background-color: #D32F2F;
}
.manageProfessionalsColorInput {
    width: 50px;
    height: 35px;
    border: none;
    margin-bottom: 10px;
    cursor: pointer;
  }
  