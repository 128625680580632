body {
    font-family: 'Roboto', sans-serif;
    background-color: #F7F7F7;
    color: #2E2E2E;
    margin: 0;
    padding: 0;
    height: 100%;
    margin: 0;
}

.navbar {
    width: 100%;
    background-color: #c9adad;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar-left {
    flex: 1;
    display: flex;
    justify-content: center;
}

.navbar-right {
    flex: 2;
    display: flex;
    justify-content: flex-end;
}

.navbar-logo {
    max-height: 100px;
}

.navbar-menu {
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin: 0;
    padding: 0;
    width: 100%;
}

.navbar-item {
    font-size: 18px;
}

.navbar-item a {
    color: #442222;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 10px 20px;
}

.navbar-item a:hover {
    color: #A9A9A9;
}

header {
    background-color: #c9adad;
    padding: 10px 10px 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

header h1 {
    color: #FFFFFF;
    font-size: 36px;
    margin: 0;
}

.header-menu {
    display: flex;
    justify-content: space-evenly;
    gap: 5%;
}

.header-menu li {
    display: inline-block;
}

.header-menu a, .header-menu button {
    color: #FFFFFF;
    text-decoration: none;
    font-size: 18px;
    display: flex;
    align-items: center;
}

.header-menu a:hover, .header-menu button:hover {
    color: #A9A9A9;
}

.icon {
    margin-right: 8px;
    font-size: 24px; /* Aumenta el tamaño del icono */
}

.whatsapp {
    color: #25D366;
}

.instagram {
    color: #E1306C;
}

.calendar {
    color: #FF4500;
}

.location {
    color: #0073E6;
}

.content {
    max-width: 80%;
    margin: 20px auto;
    background: #FFFFFF;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.services {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.service-card {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 250px;
    text-align: center;
}

.service-card img {
    width: 100%;
    height: auto;
}

.service-card h3 {
    font-size: 20px;
    margin: 15px 0;
}

.service-card p {
    padding: 0 15px 15px;
    font-size: 16px;
    color: #666666;
}

.cta-button {
    display: inline-block;
    padding: 10px 20px;
    margin: 20px auto;
    background-color: #909090;
    color: #FFFFFF;
    text-decoration: none;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.cta-button:hover {
    background-color: #c9adad;
}

.footer {
    background-color: #c9adad;
    color: #FFFFFF;
    text-align: center;
    padding: 20px 0;
    margin-top: 20px;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.footer p {
    margin: 5px 0;
}

.social-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.map-modal {
    display: none;
    position: fixed;
    z-index: 1001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.map-modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;
    position: relative;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

/* Estilos para el formulario de ManageAvailability */
.content form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.content form .availability-group {
    display: flex;
    align-items: center;
    gap: 10px;
}

.content form .availability-group div {
    display: flex;
    flex-direction: column;
}

.content form .availability-group label {
    font-weight: bold;
}

.content form .availability-group select, 
.content form .availability-group input[type="time"], 
.content form button {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
}

.content form button {
    background-color: #c9adad;
    color: white;
    border: none;
    cursor: pointer;
}

.content form button:hover {
    background-color: #a98888;
}

/* Estilos para la tabla de disponibilidad semanal */
.weekly-schedule {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.weekly-schedule th,
.weekly-schedule td {
    border: 1px solid #E0E0E0;
    text-align: center;
}

.weekly-schedule th {
    background-color: #c9adad;
    color: #FFFFFF;
}

.weekly-schedule td {
    background-color: #FFFFFF;
}

/* Media Queries for Responsiveness */

@media (max-width: 768px) {
    .navbar-menu {
        flex-direction: row; /* Mantén los íconos en fila */
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center; /* Centra los íconos en el header */
    }

    .navbar-item {
        padding: 5px 0; /* Reduce el padding en pantallas pequeñas */
    }

    .header-menu {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .service-card {
        width: 100%;
        margin: 10px 0;
    }

    .content {
        padding: 10px;
    }

    header h1 {
        font-size: 24px;
    }

    .cta-button {
        padding: 8px 16px;
        font-size: 16px;
    }

    /* Ocultar texto en mobile */
    .navbar-item a span {
        display: none; /* Ocultar el texto en mobile */
    }

    .icon {
        margin-right: 0; /* Remover margen en mobile */
        font-size: 24px;
    }
}

@media (max-width: 480px) {
    .navbar-logo {
        max-height: 100px;
    }

    .header-menu a, .header-menu button {
        font-size: 16px;
    }

    .service-card h3 {
        font-size: 18px;
    }

    .service-card p {
        font-size: 14px;
    }
}

/* Estilos para el formulario de reserva */
.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 16px;
}

/* Estilos para el modal */
.Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    z-index: 1000;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

/* Estilos para la alerta de éxito */
.success-alert {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: green;
    color: white;
    padding: 10px;
    border-radius: 5px;
    z-index: 1000;
}

.lblTitulo {
    color: #816969;
    font-size: 25px;
    font-weight: bold;
    margin: 20px;
}

.highlightReserva {
    border: 4px solid #c9adad !important; /* Rosa vibrante */
    background-color: #fffcfb !important; /* Rosa suave de fondo */
    box-shadow: 0 0 10px #c9adad, 0 0 20px #202020, 0 0 30px #5c0731, 0 0 40px #000000;
    animation: magicGlow 2s ease-in-out infinite alternate; /* Animación de brillo */
    border-radius: 15px;
}

@keyframes magicGlow {
    0% {
        box-shadow: 0 0 10px #c9adad, 0 0 20px #202020, 0 0 30px #5c0731, 0 0 40px #740303;
    }
    100% {
        box-shadow: 0 0 20px #c9adad, 0 0 40px #572c43, 0 0 60px #ff1493, 0 0 80px #ffabd8;
    }
}

/* Asegurarte de que el cuerpo ocupe toda la altura de la ventana */
html, body {
    height: 100%;
    margin: 0;
  }
  
  /* Configura el contenedor principal para usar flexbox */
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  /* El main ocupará todo el espacio disponible, empujando el footer hacia abajo */
  main {
    flex: 1;
  }
  
/* Estilos para el Dropdown */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Footer */
  /* Footer pegado abajo */
  .footer {
    background-color: #c9adad;
    color: #FFFFFF;
    text-align: center;
    padding: 20px 0;
    margin-top: 20px;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    position: relative;
    z-index: -2;
  }
  
.footer-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.footer-links a {
  color: #FFFFFF;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.footer-links a .icon {
  margin-right: 5px;
}

@media (max-width: 768px) {
    .navbar-item a span, 
    .dropdown-button span {
        display: none; /* Ocultar el texto en mobile */
    }
}
.ReactModal__Overlay {
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.75); /* Fondo oscuro con transparencia */
  }
  
  .ReactModal__Content {
    z-index: 1001;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    margin: auto;
    position: relative;
    outline: none;
  }