.schedule {
    padding: 20px;
  }
  
  .schedule h2 {
    text-align: center;
  }
  
  .schedule form {
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  
  .schedule label {
    margin-bottom: 10px;
  }
  
  .schedule input {
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .schedule button {
    margin-top: 20px;
    padding: 10px;
    background-color: #FF69B4;
    color: black;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .schedule button:hover {
    background-color: #FF1493;
  }
  