.homeContainer {
    padding: 20px;
    background-color: #f8f4f9;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .title {
    font-size: 36px;
    font-weight: 300;
    margin-bottom: 40px;
    color: #684a6f;
  }
  
  .servicesGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    justify-content: center; /* Centrar horizontalmente */
  }
  
  .serviceCard {
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centrar el contenido dentro de la tarjeta */
  }
  
  .serviceCard:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .serviceImage {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .imageOverlay {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
  }
  
  .overlayContent {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 20px;
    transform: translateY(100%);
    transition: transform 0.3s ease;
    border-radius: 8px;
  }
  
  .imageOverlay:hover .overlayContent {
    transform: translateY(0);
  }
  
  .serviceTitle {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .serviceDescription {
    font-size: 14px;
    text-align: center; /* Centrar el texto dentro de la descripción */
  }
  
  .priceTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .priceTable th, .priceTable td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .priceTable th {
    background-color: #684a6f;
    color: white;
  }
  .buttonContainer {
    margin-top: 40px; /* Espacio encima del botón */
    display: flex;
    justify-content: center; /* Centra el botón horizontalmente */
  }
  
  .reservationButton {
    background-color: #f4a5a5; /* Color de fondo pastel */
    color: #ffffff; /* Color de texto */
    padding: 15px 30px; /* Espaciado interno del botón */
    font-size: 18px; /* Tamaño de fuente */
    border: none;
    border-radius: 50px; /* Bordes redondeados */
    text-decoration: none; /* Sin subrayado */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Sombra para el botón */
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .reservationButtonInDetail {
    background-color: #63477e; /* Color de fondo pastel */
    color: #ffffff; /* Color de texto */
    padding: 5px; /* Espaciado interno del botón */
    font-size: 18px; /* Tamaño de fuente */
    border: none;
    border-radius: 50px; /* Bordes redondeados */
    text-decoration: none; /* Sin subrayado */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Sombra para el botón */
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .reservationButton:hover {
    background-color: #c97c7c; /* Color de fondo en hover */
    transform: scale(1.05); /* Aumenta el tamaño en hover */
  }
  
  .reservationButton:active {
    transform: scale(0.95); /* Disminuye el tamaño al hacer clic */
  }

  .mostChosenLabel {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #ff7676;
    color: white;
    font-size: 10px;
    padding: 2px 5px;
    border-radius: 3px;
}

.mostChosenService {
    background-color: #ffe4e1; /* Color sutil y destacado para servicios más elegidos */
    position: relative;
}

.serviceNameCell {
    position: relative;
    padding: 10px;
}
.detalleServicioOfrecido {
  font-size: 0.9em;
  font-weight: bold;
  color: grey;
  word-wrap: break-word; /* Esto asegura que el texto largo se divida en líneas */
  max-width: 100%; /* Esto asegura que el contenido no exceda el ancho del contenedor */
}

.accordionContent {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
}

.accordionContent.expanded {
  max-height: 200px; /* Ajusta este valor según la cantidad de contenido que tengas */
  padding: 10px 0;
  max-width: 100%; /* Asegura que el ancho del acordeón no exceda el ancho del modal */
}

.accordionDetail {
  background-color: #e5daff;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-width: 100%; /* Limita el ancho del detalle para que se ajuste al contenedor */
  word-wrap: break-word; /* Asegura que las palabras largas se rompan en líneas nuevas */
}